<template>
  <div class="content">
    <PageHeader :title="`${message.subject}`" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <h5 class="card-header">{{$t('properties.title')}}</h5>
            <div class="card-body block-el">
              <form v-if="fetched" action="#" class="form-horizontal">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-body">
                      <div class="form-group row">
                        <label
                          class="control-label text-right text-left-m col-md-3 weight-500"
                          >ID</label
                        >
                        <label class="control-label text-left col-md-9">{{
                          $route.params.id
                        }}</label>
                      </div>
                      <div class="form-group row">
                        <label
                          class="control-label text-right text-left-m col-md-3 weight-500"
                          >{{$t('generic-str.sent-in')}}</label
                        >
                        <label class="control-label text-left col-md-9">{{
                          message.created_at | date('DD/MM/YYYY HH:mm:ss')
                        }}</label>
                      </div>
                      <div class="form-group row">
                        <label
                          class="control-label text-right text-left-m col-md-3 weight-500"
                          >{{$t('generic-str.lbl-sender')}}</label
                        >
                        <label class="control-label text-left col-md-9">{{
                          message.from
                        }}</label>
                      </div>
                      <div class="form-group row">
                        <label
                          class="control-label text-right text-left-m col-md-3 weight-500"
                          >{{$t('generic-str.lbl-recipient')}}</label
                        >
                        <label class="control-label text-left col-md-9">{{
                          message.to
                        }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-body">
                      <div class="form-group row">
                        <label
                          class="control-label text-right text-left-m col-md-3 weight-500"
                          >{{$t('generic-str.type')}}</label
                        >
                        <label class="control-label text-left col-md-9">
                          <template v-if="message.direction == 'outbound'"
                            >{{$t('generic-str.send-2')}}</template
                          >
                          <template v-else-if="message.direction == 'inbound'"
                            >{{$tc('generic-str.status.lbl-response', 1)}}</template
                          >
                        </label>
                      </div>
                      <div class="form-group row">
                        <label
                          class="control-label text-right text-left-m col-md-3 weight-500"
                          >{{$t('generic-str.lbl-cost')}}</label
                        >
                        <label class="control-label text-left col-md-9"
                          >R${{ message.price || currency4D }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div v-else class="qt-block-ui" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card">
            <h5 class="card-header">Histórico de Eventos</h5>
            <div class="card-body block-el" style="min-height: 340px;">
              <div v-if="fetched" class="timeline timeline-border">
                <div
                  v-for="event in message.events"
                  :key="event.id"
                  class="timeline-list timeline-border"
                  :class="{
                    'timeline-info': event.status == 'sent',
                    'timeline-danger':
                      event.status == 'dropped' || event.status == 'bounced',
                    'timeline-success':
                      event.status == 'delivered' ||
                      event.status == 'open' ||
                      event.status == 'click',
                  }"
                >
                  <div class="timeline-info">
                    <div>{{ event.description }}</div>
                    <small class="text-muted">
                      {{ event.created_at | date('DD/MM/YY HH:mm') }}
                    </small>
                  </div>
                </div>
                <div v-if="message.events.length == 0">
                  <div>
                    <div style="text-align: center; font-size:40px; margin-top: 60px;">
                      <i class="fas fa-clock icon-no-item"></i>
                      <h4 class="icon-no-item">Nenhum evento até o momento</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="qt-block-ui" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card">
            <h5 class="card-header">Gráfico</h5>
            <div class="card-body block-el" style="min-height: 340px;">
              <div v-if="fetched">
                <v-chart class="chart" :option="optionReport" />
              </div>
              <div v-else class="qt-block-ui" />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <h5 class="card-header">Preview</h5>
            <div class="card-body block-el">
              <div v-if="fetchedPreview">
                <div v-html="bodyPreview"></div>
                <div v-if="bodyPreview == null" style="text-align: center; font-size:40px; margin-top: 30px;">
                  <i class="fas fa-code"></i>
                  <h4>Sem preview</h4>
                </div>
              </div>
              <div v-else class="qt-block-ui" />
            </div>
          </div>
        </div>
        <div class="col-12" v-if="this.attachments.length > 0">
          <div class="card">
            <h5 class="card-header">Anexos</h5>
            <div class="card-body">
              <div class="content-upload relative row">
                <div class="drop-img file-unic col-4" v-for="(item, i) of this.attachments" :key="i" style="margin-top: 0px !important; height: 80px !important;">
                  <a :href="item.url" target="_blank" style="width: 100%; height: 100%; padding-top: 20px; display: block;"><span class="material-icons-outlined" style="margin-top: 0px !important;">{{getTypeIcon(item.file_type)}}</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import MailService from '@/services/mail.service';
import { PieChart } from 'echarts/charts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { graphic } from 'echarts';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
]);

export default {
  name: 'EmailDetail',
  components: {
    PageHeader,
    VChart,
  },
  data() {
    return {
      message: {},
      optionReport: {
        tooltip: {
          backgroundColor: '#fff',
          formatter: (params) => {
            const template = `<span class="text-left"><h3 class="color-black no-margin text-left">${params.name}</h3> <span class="text-left" style="display:block; color: #000 !important;">${params.value}</span></span>`;
            return template;
          },
        },
        toolbox: {
          show: false,
        },
        color: ['#f62d51', '#dddddd'],
        calculable: true,
        series: [
          {
            type: 'pie',
            radius: [70, 100],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 5,
            },
            data: [],
          },
        ],
      },
      bodyPreview: null,
      fetched: false,
      fetchedPreview: false,
      isSending: false,
      integration: {},
      form: {},
      pages: 1,
      attachments: [],
    };
  },
  created() {
    this.fetch();
  },
  provide: {
    [THEME_KEY]: 'light',
  },
  methods: {
    fetch() {
      MailService.getMessage(this.$route.params.id).then(
        (response) => {
          this.fetched = true;
          this.message = response.data;
          if (this.message.batch.attachments && this.message.batch.attachments.length > 0) {
            this.attachments = JSON.parse(this.message.batch.attachments[0].encoded);
          }
          console.log(this.message);
          this.optionReport.series[0].data.push(
            {
              value: this.message.clicks,
              name: 'Cliques',
            },
          );
          this.optionReport.series[0].data.push(
            {
              value: this.message.opens,
              name: 'Aberturas',
            },
          );
        },
        (error) => {
          this.content = error;
        },
      );
      MailService.emailPreview(this.$route.params.id).then(
        (response) => {
          this.fetchedPreview = true;
          console.log('Preview');
          console.log(response);
          if (response.data) {
            this.bodyPreview = response.data;
          }
        },
        (error) => {
          this.content = error;
        },
      );
    },
    getTypeIcon(type) {
      if (type.includes('image')) {
        return 'image';
      }
      if (type.includes('video')) {
        return 'play_circle';
      }
      if (type.includes('audio')) {
        return 'music_note';
      }
      return 'content_copy';
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    fillData() {
      this.dataCollection = {
        labels: ['10:00', '11:00'],
        datasets: [
          {
            label: 'Abertos',
            backgroundColor: '#f87979',
            data: [40, 20],
          },
          {
            label: 'Clicados',
            backgroundColor: '#f87979',
            data: [40, 10],
          },
        ],
      };
    },
  },
};
</script>
<style scoped lang="scss">
.mail-report {
  margin: 0px !important;
  width: 100% !important;
}
.mail-report .invoice-header {
  padding-top: 0px;
}
.mail-report .invoice-summary {
  margin-top: 10px !important;
}
.card-body {
  min-height: 300px;
}
.chart {
  height: 300px;
}
.content-upload {
  min-height: 100px;
  width: 100%;
  border-radius: 8px;
  text-align: center;
  padding-left: 18px;
  padding-right: 18px;
  align-content: center;
}

.content-upload span {
  color: #5A99EC;
  font-size: 50px;
}
.content-upload h4 {
  font-size: 14px;
  font-weight: 400;
  color: #595959;
}
.content-upload {
  cursor: pointer;
}
.file-unic {
  float: none !important;
  margin: 0 auto !important;
}
.file-unic:hover {
  background-color: #f1f1f1;
}
.content-inside {
  padding: 10px;
}
.content-upload span {
  font-size: 30px;
}
.file-unic {
  margin-top: 0px !important;
}
.drop-img {
  height: 60px !important;
}
.file-unic {
  margin-top: 10% !important;
  float: left !important;
  margin: 5px !important;
  width: 40% !important;
  margin-bottom: 5px !important;
}
</style>
